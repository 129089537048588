import React, { useContext } from 'react';

// eslint-disable-next-line no-unused-vars
import Actions from '../Actions';
import { ConfigurationContext } from '../../contexts/ConfigurationContext';
// eslint-disable-next-line no-unused-vars
import { DialogContext } from '../../contexts/DialogContext';
import { EventsContext } from '../../contexts/EventsContext';
// eslint-disable-next-line no-unused-vars
import { Local } from '../../tools/storage';
import PropTypes from 'prop-types';
import Skeleton from '../Skeleton';
import { UserActionContext } from '../../contexts/UserActionContext';
//import-voice
import c from 'classnames';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
const images = localStorage.getItem('dydu.images');

const TEASER_TYPES = {
  AVATAR_AND_TEXT: 0,
  AVATAR_ONLY: 1,
  TEXT_ONLY: 2,
};

/**
 * Minified version of the chatbox.
 */
export default function Teaser({ open, toggle }) {
  const { configuration } = useContext(ConfigurationContext);
  const event = useContext(EventsContext).onEvent('teaser');
  const classes = useStyles({ configuration });
  const { ready, t } = useTranslation('translation');
  const { tabbing } = useContext(UserActionContext) || false;
  const logo = images && JSON.parse(images) && JSON.parse(images).logo;
  const title = t('teaser.title');
  const mouseover = t('teaser.mouseover');
  const voice = configuration.Voice ? configuration.Voice.enable : false;
  // DISPLAY TEASER TYPE
  const { AVATAR_AND_TEXT, AVATAR_ONLY, TEXT_ONLY } = TEASER_TYPES;
  const initialTeaserType =
    !configuration.teaser.displayType ||
    configuration.teaser.displayType > TEXT_ONLY ||
    configuration.teaser.displayType < AVATAR_AND_TEXT
      ? AVATAR_AND_TEXT
      : configuration.teaser.displayType;

  const onClick = () => {
    event('onClick');
    toggle(2)();
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <div className={c('dydu-teaser', classes.root, { [classes.hidden]: !open })}>
      <div className={c('dydu-teaser-container', classes.dyduTeaserContainer)}>
        <div
          onClick={onClick}
          onKeyDown={onKeyDown}
          title={mouseover}
          role="button"
          tabIndex="0"
          aria-pressed={!open}
          className={c('dydu-teaser-title', classes.dyduTeaserTitle, {
            [classes.hideOutline]: !tabbing,
          })}
        >
          {(initialTeaserType === AVATAR_AND_TEXT || initialTeaserType === TEXT_ONLY) && (
            <div className={c('dydu-teaser-button', classes.button)}>
              <Skeleton children={title} hide={!ready} width="3em" />
            </div>
          )}
          {(initialTeaserType === AVATAR_AND_TEXT || initialTeaserType === AVATAR_ONLY) && (
            <div className={c('dydu-teaser-brand', classes.brand)}>
              <img alt="" src={logo || `${process.env.PUBLIC_URL}assets/${configuration.avatar.response.image}`} />
            </div>
          )}
        </div>
        {open && voice && <voice />}
      </div>
    </div>
  );
}

Teaser.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
