export const SOLUTION_TYPE = {
  assistant: 'ASSISTANT',
  livechat: 'LIVECHAT',
};

export const RESPONSE_QUERY_FORMAT = {
  json: 'JSon',
  xml: 'XML',
  jsonDydu: 'JSonDydu',
  vocal: 'Vocal',
  html: 'Html',
};

export const VAR_TYPE = {
  string: 'string',
  object: 'object',
  array: 'array',
  number: 'number',
};
